import React, { useEffect, useState } from 'react';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { AlertDialog, Box, Center, Heading, HStack, Pressable, Skeleton, Spinner, Stack, Text, VStack } from 'native-base';
import UserImage from '../../Components/UserImage';
import APIAction from '../../../Actions/APIAction';
import { ImagePicker } from '../../../Libs/FilePicker';
import TranslationAction from '../../../Actions/TranslationAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Name = (props) => {

    const
        [openUploader, setOpenUploader] = useState(false),
        [loading, setLoading] = useState(false),
        [language, setLanguage] = useState(false)
        ;

    useEffect( () => {
        const init = async () => {
            let language = await TranslationAction.getSelectedLanguage();
            setLanguage(language);
        }
        init();
    }, []);

    /**
     * Upload profile picture
     *
     * @param response
     * @returns {Promise<void>}
     */
    let upload = async (response) => {

        setOpenUploader(false);
        setLoading(true);

        // Upload file
        let file = await APIAction.request({
            method: 'post', url: '/api/file/upload', body: {
                file: response,
            }, type: 'multipart/form-data',
        });

        // Link to entity
        if (file && file.id.length) {
            // Update profile image on screen
            let updateUrl = '/api/users/update/image'
            await APIAction.request({
                method: 'PATCH', url: updateUrl, body: {
                    imageId: file.id,
                    userId: props.user.id,
                },
            });
        }

        setLoading(false);

        // Reload
        if (typeof props.onReload === 'function') {
            props.onReload();
        }
    };

    return (
        <>
            <Box style={[props.style]}>
                {
                    props.user ?
                        <Box>
                            <Stack
                                space={{
                                    base: 2,
                                    md: 5
                                }}
                                direction={{
                                    base: 'column',
                                    md: 'row'
                                }}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Pressable
                                    onPress={() => {
                                        setOpenUploader(true);
                                    }}
                                >
                                    <UserImage
                                        style={{ width: 100, height: 100 }}
                                        textStyle={{ fontSize: 40, lineHeight: 100 }}
                                        user={props.user}
                                    />
                                </Pressable>

                                <VStack>
                                    <Heading>{props.user.firstName + ' ' + props.user.lastName}</Heading>
                                    <HStack
                                        space={1}
                                        justifyContent={{
                                            base: 'center',
                                            md: 'flex-start'
                                        }}
                                    >
                                        {props.user.employee.gender === 'M' &&
                                            <>
                                                <Icon name={'mars'} size={18} style={{ color: '#00aaff' }} />
                                                <Text><Trans>Male</Trans></Text>
                                            </>
                                        }
                                        {props.user.employee.gender === 'F' &&
                                            <>
                                                <Icon name={'venus'} size={18} style={{ color: '#ff3cc8' }} />
                                                <Text><Trans>Female</Trans></Text>
                                            </>
                                        }
                                        {props.user.employee.gender === 'X' &&
                                            <>
                                                <Icon name={'venus-mars'} size={18} style={{ color: '#000' }} />
                                                <Text><Trans>X</Trans></Text>
                                            </>
                                        }
                                        <Icon name={'suitcase'} size={18} style={{ color: '#000' }} />
                                        <Text>{props.user?.employee?.contractType?.name[language.code]}</Text>
                                    </HStack>
                                </VStack>
                            </Stack>
                        </Box>
                        :
                        <Box>
                            <Stack
                                space={{
                                    base: 2,
                                    md: 5
                                }}
                                direction={{
                                    base: 'column',
                                    md: 'row'
                                }}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Skeleton h={100} w={100} borderRadius={'full'} />
                                <Skeleton.Text lines={2} width={250} />
                            </Stack>
                        </Box>
                }
            </Box>
            <ImagePicker
                open={openUploader}
                onChange={(response) => {
                    upload(response);
                }}
                onTrigger={(value) => {
                    setOpenUploader(value);
                }}
            />
            <AlertDialog isOpen={loading}>
                <AlertDialog.Content>
                    <AlertDialog.Body>
                        <Center>
                            <Spinner size={'lg'} />
                        </Center>
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
        </>
    );
};

export default Name;
