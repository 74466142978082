import database from '../Database/Database';
import { Q } from '@nozbe/watermelondb';
import APIAction from './APIAction';

const JobPromoAction = {
    sync: () => {

        return new Promise(async (resolve, reject) => {
            //send accepted and rejected
            let changeRequests = [];
            let acceptedJobs = await database.collections.get('job_promo').query(
                Q.where('accepted', true),
            ).fetch();

            for (let job of acceptedJobs) {
                changeRequests.push(APIAction.request({
                    method: 'PATCH',
                    url: '/api/job_promos/' + job.jobPromoId + '/accept',
                    body: {
                        date: new Date(),
                    },
                    catchFail: false
                }));
            }

            let rejectedJobs = await database.collections.get('job_promo').query(
                Q.where('rejected', true),
            ).fetch();

            for (let job of rejectedJobs) {
                changeRequests.push(APIAction.request({
                    method: 'PATCH',
                    url: '/api/job_promos/' + job.jobPromoId + '/refuse',
                    body: {
                        date: new Date(),
                    }
                }));
            }

            await Promise.all(changeRequests);

            // Get jobs
            let apiJobs = await APIAction.request({
                method: 'GET',
                url: '/api/job_promos'
            });

            if ('hydra:member' in apiJobs) {
                apiJobs = apiJobs['hydra:member'];
            } else {
                apiJobs = [];
            }

            //get local jobs
            let localJobs = await database.collections.get('job_promo').query().fetch();

            //compare
            let toCreate = [];
            let toRemove = [];
            let toUpdate = [];

            //add jobs to create
            for (let localJob of localJobs) {
                let found = false;
                for (let apiJob of apiJobs) {
                    if (localJob.jobPromoId === apiJob.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    toRemove.push(localJob);
                }
            }

            //add jobs to remove
            for (let apiJob of apiJobs) {
                let found = false;
                for (let localJob of localJobs) {
                    if (localJob.jobPromoId === apiJob.id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    toCreate.push(apiJob);
                }
            }

            //add jobs to update
            for (let apiJob of apiJobs) {
                for (let localJob of localJobs) {
                    if (localJob.jobPromoId === apiJob.id) {
                        toUpdate.push({ apiJob, localJob });
                        break;
                    }
                }
            }

            //create/remove jobs
            await database.write(async () => {

                for (let job of toRemove) {
                    await job.destroyPermanently();
                }

                for (let { apiJob, localJob } of toUpdate) {
                    await localJob.update(job => {
                        job.startAt = new Date(apiJob.shift.startOn);
                        job.data = apiJob;
                    });
                }

                for (let job of toCreate) {
                    await database.get('job_promo').create(newJob => {
                        newJob.jobPromoId = job.id;
                        newJob.seen = false;
                        newJob.accepted = false;
                        newJob.rejected = false;
                        newJob.open = false;
                        newJob.startAt = new Date(job.shift.startOn);
                        newJob.data = job;
                    });
                }

                resolve();
            });
        });
    },

    getAll: async (startAt = null, endAt = null) => {

        let queryConditions = [
            Q.where('accepted', false),
            Q.where('rejected', false),
            Q.sortBy('start_at', Q.asc)
        ];

        if (startAt !== null) {
            startAt = new Date(startAt);
            startAt.setHours(0, 0, 0, 0);
            queryConditions.push(Q.where('start_at', Q.gte(startAt.getTime())));
        }

        if (endAt !== null) {
            endAt = new Date(endAt);
            endAt.setHours(23, 59, 59, 999);
            queryConditions.push(Q.where('start_at', Q.lte(endAt.getTime())));
        }

        return await database.collections.get('job_promo').query(
            ...queryConditions
        ).fetch();
    },

    get: (jobPromoId) => {
        return new Promise(async (resolve, reject) => {
            await database.write(async () => {
                let job = await database.collections.get('job_promo').query(
                    Q.where('job_promo_id', jobPromoId),
                    Q.take(1)
                ).fetch();

                if (job.length === 0) {
                    resolve(null);
                } else {
                    resolve(job[0]);
                }
            });
        });
    },

    getUnseenCount: async () => {
        let count = await database.collections.get('job_promo').query(
            Q.where('seen', false),
        ).fetchCount();

        count = (count > 99 ? '∞' : count);
        count = (count === 0 ? null : count);

        return count;
    },

    getOpen: () => {
        return new Promise(async (resolve, reject) => {
            await database.write(async () => {
                let openItem = await database.collections.get('job_promo').query(
                    Q.where('accepted', false),
                    Q.where('rejected', false),
                    Q.where('open', true),
                    Q.take(1)
                ).fetch();

                if (openItem.length === 0) {
                    openItem = null;
                } else {
                    openItem = openItem[0];
                }

                resolve(openItem);
            });
        });
    },

    setOpen: async (jobPromoId) => {
        let unsetPromise = new Promise(async (resolve1, reject1) => {
            await database.write(async () => {
                let openItems = await database.collections.get('job_promo').query(
                    Q.where('open', true),
                    Q.take(1)
                ).fetch();

                for (let openItem of openItems) {
                    await openItem.update(job => {
                        job.open = false;
                    });
                }

                resolve1();
            });
        });
            
        let setPromise = await new Promise(async (resolve2, reject2) => {

            let job = await database.collections.get('job_promo').query(
                Q.where('job_promo_id', jobPromoId),
                Q.take(1)
            ).fetch();

            if (job.length === 0) {
                resolve2(false);
            }

            await job[0].update(job => {
                job.open = true;
            });
            resolve2(true);
        });

        await unsetPromise;
        return await setPromise;
    },

    setSeen: async (jobPromoId) => {
        await database.write(async () => {
            let job = await database.collections.get('job_promo').query(
                Q.where('job_promo_id', jobPromoId),
                Q.take(1)
            ).fetch();

            if (job.length === 0) {
                return;
            }

            await job[0].update(job => {
                job.seen = true;
            });
        });
    },

    setAccepted: async (jobPromoId) => {
        await database.write(async () => {
            let job = await database.collections.get('job_promo').query(
                Q.where('job_promo_id', jobPromoId),
                Q.take(1)
            ).fetch();

            if (job.length === 0) {
                return;
            }

            await job[0].update(job => {
                job.accepted = true;
            });
        });
    },

    setRefused: async (jobPromoId) => {
        await database.write(async () => {
            let job = await database.collections.get('job_promo').query(
                Q.where('job_promo_id', jobPromoId),
                Q.take(1)
            ).fetch();

            if (job.length === 0) {
                return;
            }

            await job[0].update(job => {
                job.rejected = true;
            });
        });
    }
};

export default JobPromoAction;