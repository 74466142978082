import { appSchema, tableSchema } from '@nozbe/watermelondb';

export default appSchema({
    version: 2,
    tables: [
        tableSchema({
            name: 'language',
            columns: [
                { name: 'code', type: 'string' },
                { name: 'name', type: 'string' },
                { name: 'is_selected', type: 'boolean' },
            ]
        }),
        tableSchema({
            name: 'translation',
            columns: [
                { name: 'key', type: 'string' },
                { name: 'value', type: 'string' },
                { name: 'language_id', type: 'string', isIndexed: true },
            ]
        }),
        tableSchema({
            name: 'translation_sync',
            columns: [
                { name: 'performed_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'current_user',
            columns: [
                { name: 'user_id', type: 'string' },
                { name: 'email', type: 'string' },
                { name: 'phone', type: 'string' },
                { name: 'first_name', type: 'string' },
                { name: 'last_name', type: 'string' },
                { name: 'employee', type: 'string' },
                { name: 'language', type: 'string', isOptional: true },
                { name: 'client', type: 'string', isOptional: true },
                { name: 'is_identity_verified', type: 'boolean' },
                { name: 'is_payment_verified', type: 'boolean' },
            ]
        }),
        tableSchema({
            name: 'setting',
            columns: [
                { name: 'key', type: 'string', isIndexed: true },
                { name: 'value', type: 'string' },
                { name: 'updated_at', type: 'number' },
            ]
        }),
        tableSchema({
            name: 'request_cache',
            columns: [
                { name: 'hash_value', type: 'string', isIndexed: true },
                { name: 'created_at', type: 'number', isIndexed: true },
                { name: 'finished_at', type: 'number', isOptional: true, isIndexed: true },
                { name: 'method_value', type: 'string' },
                { name: 'url_value', type: 'string' },
                { name: 'params_value', type: 'string' },
                { name: 'body_value', type: 'string' },
                { name: 'response_value', type: 'string', isOptional: true },
            ]
        }),
        tableSchema({
            name: 'job_promo',
            columns: [
                { name: 'job_promo_id', type: 'string', isIndexed: true },
                { name: 'seen', type: 'boolean' },
                { name: 'accepted', type: 'boolean' },
                { name: 'rejected', type: 'boolean' },
                { name: 'open', type: 'boolean' },
                { name: 'start_at', type: 'number' },
                { name: 'entity_data', type: 'string' },
            ]
        }),
    ]
});