import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Box, Heading, Divider, Button, Text, Modal, VStack, FormControl, Center, HStack, IconButton, Stack, Skeleton, Image, AlertDialog, Actionsheet, useBreakpointValue, useTheme } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import { ImagePicker, DocumentPicker, types } from '../../../../Libs/FilePicker';
import Trans from '../../../Components/Trans';
import ScaledImage from '../../../Components/ScaledImage';
import APIAction from '../../../../Actions/APIAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import RequestCacheAction from '../../../../Actions/RequestCacheAction';
import FileFunctions from '../../../../Libs/FileFunctions';
import { v4 as uuidv4 } from 'uuid';

import main from '../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const DocumentItem = (props) => {
    const { colors } = useTheme();

    return (
        <>
            {props.imageSource.uri.includes('application') ?
                <Center>
                    <Icon name={'pdf-file'} size={props.size} color={colors['primary']['600']} />
                    <Text>{props.imageSource.name}</Text>
                </Center>
                :
                <Image
                    height={props.size}
                    width={props.size}
                    resizeMode={'contain'}
                    source={props.imageSource}
                />
            }
        </>
    );
};

const CostListItem = (props) => {

    const [imageSource, setImageSource] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const { colors } = useTheme();

    const getData = useCallback(() => {
        const init = async () => {
            let getImage = await FileFunctions.download({ url: props.value.file });
            setImageSource(getImage);
        };
        init();
    }, [setImageSource, props.value.file]);

    useEffect(() => {
        getData();
    }, [getData]);

    return (
        <Box
            flex={{
                lg: 1
            }}
        >
            <Center>
                <Stack
                    style={[mainStyle.borderRadius]}
                    space={3}
                    direction={{
                        base: 'column',
                        md: 'row'
                    }}
                    justifyContent={{
                        base: 'flex-start',
                        md: 'space-between',
                    }}
                    alignItems={'flex-start'}
                >


                    <Box>
                        <Pressable
                            onPress={() => {
                                setOpenImage(true);
                            }}
                        >

                            {imageSource === null || !('uri' in imageSource) ?
                                <Skeleton width={100} height={100} />
                                :
                                <DocumentItem imageSource={imageSource} size={100} />
                            }
                        </Pressable>
                    </Box>
                </Stack>
            </Center>
            <AlertDialog isOpen={openImage} onClose={() => setOpenImage(false)} closeOnOverlayClick={true}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Body>
                        {imageSource && ('uri' in imageSource) ?
                            <VStack space={2}>
                                <Center>
                                    <VStack space={2}>
                                        <DocumentItem imageSource={imageSource} size={300} />
                                    </VStack>
                                </Center>
                                <IconButton icon={<Icon color={colors['primary']['600']} size={24} name={'alternate-pencil'} />} colorScheme={'light'} variant={'subtle'}
                                    onPress={() => {
                                        if (typeof props.onOpen === 'function') {
                                            setOpenImage(false);
                                            props.onOpen();
                                        }
                                    }}
                                />
                                <IconButton icon={<Icon color={colors['primary']['600']} size={24} name={'download'} />} colorScheme={'light'} variant={'subtle'}
                                    onPress={() => {
                                        if (typeof props.onOpen === 'function') {
                                            setOpenImage(false);
                                            FileFunctions.share(imageSource);
                                        }
                                    }}
                                />
                                <IconButton icon={<Icon color={colors['danger']['600']} size={24} name={'trash'} />} colorScheme={'danger'} variant={'subtle'}
                                    onPress={() => {
                                        if (typeof props.onOpen === 'function') {
                                            setOpenImage(false);
                                            setShowDelete(true);
                                        }
                                    }}
                                />
                            </VStack>
                            :
                            <Center>
                                <Skeleton width={300} height={300} />
                            </Center>
                        }
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
            <AlertDialog isOpen={showDelete} onClose={() => setShowDelete(false)} closeOnOverlayClick={true}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text style={[mainStyle.modalTitle]}><Trans>Delete</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <VStack space={2}>
                            <Center>
                                <DocumentItem imageSource={imageSource} size={100} />
                            </Center>
                            <Center>
                                <Text><Trans>Are you shure you want to delete this document?</Trans></Text>
                            </Center>
                        </VStack>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button variant={'unstyled'} onPress={() => setShowDelete(false)}>
                                <Text><Trans>Close</Trans></Text>
                            </Button>
                            <Button colorScheme={'danger'} onPress={() => {
                                const init = async () => {
                                    let result = await APIAction.request({
                                        method: 'DELETE', url: '/api/employee_costs/' + props.value.id
                                    });
                                    await RequestCacheAction.clear({ url: '/api/employee_costs' });
                                    setShowDelete(false);
                                    props.onReload();
                                };
                                init();
                            }}>
                                <Text color={'#fff'}><Trans>Delete</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </Box>
    );
}

const CostForm = (props) => {

    const [showModal, setShowModal] = useState(false),
        [showImagePicker, setShowImagePicker] = useState(false),
        [showDocumentPicker, setShowDocumentPicker] = useState(false),
        [uploadedImage, setUploadedImage] = useState(null),
        [formData, setFormData] = useState({
            costId: null,
            employeeShiftId: props.employeeShift.id,
            description: null,
            amount: null
        }),
        [invalidFields, setInvalidFields] = useState({
            description: false,
            amount: false
        }),
        [costItems, setCostItems] = useState([]),
        [dialogId, setDialogId] = useState(uuidv4()),
        [showPickerType, setShowPickerType] = useState(false),
        isWideScreen = useBreakpointValue({ base: false, lg: true })
        ;

    let descriptionRef = useRef(null),
        amountRef = useRef(null);

    const getData = useCallback(() => {
        const init = async () => {
            //get costs
            let costs = await APIAction.request({
                method: 'GET', url: '/api/employee_costs', cache: false, params: {
                    employeeShiftId: props.employeeShift.id
                }
            });
            costs = costs['hydra:member'];

            //loop costs
            let costJsx = [];
            for (let value of costs) {
                costJsx.push(
                    <CostListItem
                        value={value}
                        onOpen={() => {
                            setUploadedImage(null);
                            setFormData({
                                costId: value.id,
                                employeeShiftId: props.employeeShift.id,
                                description: value.description,
                                amount: value.amount
                            });
                            descriptionRef.current = value.description;
                            amountRef.current = parseFloat(value.amount).toFixed(2);
                            setDialogId(uuidv4());
                            setShowModal(true);
                        }}
                        l 

                        onReload={() => getData()}
                    />
                );
            }
            setCostItems(costJsx);

        };
        init();
    }, [props]);

    useEffect(() => {
        getData();
    }, [props, getData]);

    const submit = async () => {

        let error = false;

        //all fields are valid to start
        let newInvalidFields = {};
        for (let [index, value] of Object.entries(invalidFields)) {
            newInvalidFields[index] = false;
        };

        /**
        //check if valid description
        if (null === formData.description) formData.description = '';
        if ((formData.description).trim() === '') {
            error = true;
            await GeneralAction.toast('error', <Trans>Fill in description</Trans>);
            await GeneralAction.sleep(100);
            newInvalidFields = { ...newInvalidFields, ...{ description: true } };
        }

        //check if valid amount
        if (formData.amount < 0.01) {
            error = true;
            await GeneralAction.toast('error', <Trans>Amount is not valid</Trans>);
            await GeneralAction.sleep(100);
            newInvalidFields = { ...newInvalidFields, ...{ amount: true } };
        }
            **/

        //check if file is uploaded
        if (null === uploadedImage && null === formData.costId) {
            error = true;
            await GeneralAction.toast('error', <Trans>No receipt included</Trans>);
            await GeneralAction.sleep(100);
        }

        if (false === error) {

            //set url en method
            let url = '/api/employee_costs';

            //set body
            let body = {
                ...formData
            };

            //set file if needed
            if (null !== uploadedImage) {
                body = {
                    ...body,
                    file: uploadedImage
                };
            }

            //end form to backend
            let result = await APIAction.request({
                method: 'POST', url: url, body: body, type: 'multipart/form-data'
            });

            //clear cache
            await RequestCacheAction.clear({ url: '/api/employee_costs' });

            //close modal
            setShowModal(false);

            //show msg
            await GeneralAction.toast('success', <Trans>Cost saved</Trans>);

            //renew
            getData();
        }

        setInvalidFields(newInvalidFields);
    };

    let costItemsSorted = [];
    if (isWideScreen) {
        for (let i = 1; Math.ceil(costItems.length / 3) >= i; i++) {
            costItemsSorted.push(
                <HStack space={2}>
                    {costItems.slice((i - 1) * 3, i * 3)}
                </HStack>
            );
        }
    }

    return (
        <>
            <Box style={mainStyle.boxItemVertical}>
                <Heading style={mainStyle.mediumTitle}><Trans>Costs</Trans></Heading>
                <Divider my={3} style={mainStyle.dividerStyle} />
                <VStack space={2} divider={<Divider style={[mainStyle.dividerStyle]} />}>
                    <VStack
                        space={2}
                    >
                        {isWideScreen ?
                            costItemsSorted
                            :
                            costItems
                        }
                    </VStack>
                    <Button onPress={() => {
                        setUploadedImage(null);
                        setFormData({
                            costId: null,
                            employeeShiftId: props.employeeShift.id,
                            description: null,
                            amount: null
                        });
                        descriptionRef.current = null;
                        amountRef.current = null;
                        setDialogId(uuidv4());
                        setShowModal(true);
                    }}>
                        <Text color={'#fff'}><Trans>Add</Trans></Text>
                    </Button>
                </VStack>
            </Box>

            {/* Modal */}
            <Modal isOpen={showModal && !showPickerType && !showImagePicker && !showDocumentPicker} onClose={() => setShowModal(false)} avoidKeyboard key={dialogId}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header><Text style={[mainStyle.modalTitle]}><Trans>Cost</Trans></Text></Modal.Header>
                    <Modal.Body>
                        <VStack space={2}>
                            <FormControl isRequired>
                                <FormControl.Label><Text><Trans>Upload receipt</Trans></Text></FormControl.Label>
                                <VStack space={2}>
                                    {uploadedImage && !uploadedImage.uri.includes('application') &&
                                        <Center>
                                            <ScaledImage
                                                uri={uploadedImage.uri}
                                                height={200}
                                                style={{
                                                    resizeMode: 'contain'
                                                }}
                                            />
                                        </Center>
                                    }
                                    {uploadedImage && uploadedImage.uri.includes('application') &&
                                        <Center>
                                            <Icon name={'pdf-file'} size={100} color={'#00AAFF'} />
                                            <Text>{uploadedImage.name}</Text>
                                        </Center>
                                    }

                                    <Button onPress={() => {
                                        setShowPickerType(true);
                                    }}>
                                        <Text color={'#fff'}><Trans>Upload</Trans></Text>
                                    </Button>
                                </VStack>
                            </FormControl>
                        </VStack>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={2}>
                            <Button variant={'ghost'} onPress={() => setShowModal(false)}>
                                <Text><Trans>Close</Trans></Text>
                            </Button>
                            <Button onPress={() => submit()}>
                                <Text color={'#fff'}><Trans>Save</Trans></Text>
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <Actionsheet isOpen={showPickerType} onClose={() => setShowPickerType(false)}>
                <Actionsheet.Content>
                    <Actionsheet.Item onPress={() => {
                        setShowPickerType(false);
                        setShowImagePicker(true);
                    }}><Text><Trans>Image</Trans></Text></Actionsheet.Item>
                    <Actionsheet.Item onPress={() => {
                        setShowPickerType(false);
                        setShowDocumentPicker(true);
                    }}><Text><Trans>PDF document</Trans></Text></Actionsheet.Item>
                    <Actionsheet.Item onPress={() => {
                        setShowPickerType(false);
                    }}><Text><Trans>Cancel</Trans></Text></Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>
            <ImagePicker open={showImagePicker} onTrigger={(val) => setShowImagePicker(val)} onChange={(val) => {
                setUploadedImage(val);
                setShowImagePicker(false);
            }} />
            <DocumentPicker open={showDocumentPicker} onTrigger={(val) => setShowDocumentPicker(val)} types={[types.pdf]} noCamera onChange={(val) => {
                setUploadedImage(val);
                setShowDocumentPicker(false);
            }} />
        </>
    );
};

export default CostForm;