import { Model } from '@nozbe/watermelondb';
import { text, json, field, date } from '@nozbe/watermelondb/decorators';

const sanitizeJobPromoData = json => json;

export default class JobPromo extends Model {
    static table = 'job_promo';

    @text('job_promo_id') jobPromoId;
    @field('seen') seen;
    @field('accepted') accepted;
    @field('rejected') rejected;
    @field('open') open;
    @date('start_at') startAt;
    @json('entity_data', sanitizeJobPromoData) data;
};