import { schemaMigrations, createTable } from '@nozbe/watermelondb/Schema/migrations';

export default schemaMigrations({
    migrations: [
        {
            toVersion: 2,
            steps: [
                createTable({
                    name: 'job_promo',
                    columns: [
                        { name: 'job_promo_id', type: 'string', isIndexed: true },
                        { name: 'seen', type: 'boolean' },
                        { name: 'accepted', type: 'boolean' },
                        { name: 'rejected', type: 'boolean' },
                        { name: 'open', type: 'boolean' },
                        { name: 'start_at', type: 'number' },
                        { name: 'entity_data', type: 'string' },
                    ]
                })
            ],
        }
    ],
});