import React, { useCallback, useRef, useEffect, useState } from 'react';
import AppContainer from '../../Components/AppContainer';
import APIAction from '../../../Actions/APIAction';
import JobHistoryItem from './JobHistoryItem';
import { Skeleton, VStack } from 'native-base';
import JobPromoAction from '../../../Actions/JobPromoAction';
import { useRecoilState } from 'recoil';
import JobCountState from '../../../Recoil/JobCountState';

const JobCardHistoryScreen = ({ navigation }) => {

    const
        firstLoad = useRef(true),
        [history, setHistory] = useState(null),
        [jobCount, setJobCount] = useRecoilState(JobCountState)
        ;

    const onFirstload = useCallback(() => {
        const init = async () => {
            //get history
            let historyResult = await APIAction.request({
                method: 'GET',
                url: '/api/job_promos/history'
            });
            let history = historyResult['jobPromoHistories']['hydra:member'];
            let plannedShiftIds = historyResult['plannedShiftIds']['hydra:member'];

            //loop
            let historyItems = [];
            for (let historyItem of history) {
                historyItems.push(
                    <JobHistoryItem
                        data={historyItem}
                        isPlanned={plannedShiftIds.includes(historyItem.jobPromo.shift.id)}
                        onRefresh={() => {
                            init();
                        }}
                    />
                );
            }

            //set in states
            setHistory(historyItems);

            //resync
            await JobPromoAction.sync();

            //set job count
            let jobCount = await JobPromoAction.getUnseenCount();
            setJobCount(jobCount);
        };
        init();
    }, [setJobCount]);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstload();
        }
    }, [onFirstload]);

    return (
        <AppContainer>

            <VStack space={2}>
                {
                    history === null ?
                    <>
                        <Skeleton height={10} />
                        <Skeleton height={10} />
                        <Skeleton height={10} />
                    </>
                    :
                    <>
                        {history}
                    </>
                }
            </VStack>
        </AppContainer>
    );
};

export default JobCardHistoryScreen;