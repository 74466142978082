import React, { useState, useEffect } from 'react';
import { SafeAreaView, StyleSheet, Linking, Platform } from 'react-native';
import { Box, Image, Pressable, HStack, Menu, Text, Divider, useTheme, AlertDialog, Button, Stack, VStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../Assets/Fontello/line-awesome-config.json';
import lineAwesomeConfigBrands from '../../Assets/Fontello/line-awesome-brands-config.json';
import Trans from './Trans';
import UserAction from '../../Actions/UserAction';
import APIAction from '../../Actions/APIAction';
import GeneralAction from '../../Actions/GeneralAction';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const IconBrand = createIconSetFromFontello(lineAwesomeConfigBrands);

const mainStyle = StyleSheet.create(main);
const logo = require('../../Assets/Images/logo.png');
const isSafari = () => {
    if (Platform.OS === 'web') {
        return /Safari/.test(navigator.userAgent) &&
            /Apple Computer/.test(navigator.vendor);
    } else {
        return false;
    }
}


const Header = () => {
    const navigation = useNavigation();

    const { colors } = useTheme();

    const
        [accountRemoveModal, setAccountRemoveModal] = useState(false),
        [logoutModal, setLogoutModal] = useState(false),
        [user, setUser] = useState(null),
        [impersonation, setImpersonation] = useState(false)
        ;

    useEffect(() => {
        const init = async () => {
            let user = await UserAction.getUser();
            setUser(user);

            let isImpersonation = await AsyncStorage.getItem('Impersonate');
            setImpersonation(isImpersonation);
        }
        init();
    }, []);

    return (
        <>
            <SafeAreaView
                backgroundColor={'#fff'}
                style={impersonation ? { borderBottomColor: 'red', borderBottomWidth: 5 } : {}}
            >
                <Box style={mainStyle.header}>
                    <HStack
                        w={'100%'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Box>
                            <Pressable onPress={() => {
                                navigation.navigate('Waiting');
                            }}>
                                <Image
                                    source={logo}
                                    height={20}
                                    width={40}
                                    resizeMode={'contain'}
                                />
                            </Pressable>
                        </Box>
                        <HStack
                            space={2}
                            alignItems={'center'}
                        >
                            {impersonation &&
                                <Box>
                                    <Button
                                        colorScheme={'danger'}
                                        onPress={() => {
                                            APIAction.unimpersonate();
                                        }}
                                    >
                                        <HStack
                                            space={1}
                                            alignItems={'center'}
                                        >
                                            <Icon color={'#fff'} size={16} name={'user-slash'} />
                                            <VStack>
                                                <Text color={'#fff'} fontSize={9}><Trans>Stop impersonation</Trans></Text>
                                                {user &&
                                                    <Text color={'#fff'} fontSize={9}>{user.firstName} {user.lastName}</Text>
                                                }
                                            </VStack>

                                        </HStack>
                                    </Button>
                                </Box>
                            }
                            <Box>
                                <Menu
                                    shouldFlip={false}
                                    placement={Platform.OS === 'web' ? 'left bottom' : 'bottom left'}
                                    trigger={triggerProps => {
                                        return <Pressable {...triggerProps}><Icon color={colors['secondary']['600']} size={30} name={'wrench'} /></Pressable>
                                    }}
                                >
                                    <Menu.Item
                                        onPress={() => {
                                            Linking.openURL('https://silvertie.be/privacy-cookies');
                                        }}
                                    >
                                        <Icon size={16} name={'file-signature'} />
                                        <Text><Trans>Privacy Policy</Trans></Text>
                                    </Menu.Item>
                                    <Menu.Item
                                        onPress={() => {
                                            setAccountRemoveModal(true);
                                        }}
                                    >
                                        <Icon size={16} name={'alternate-user-slash'} />
                                        <Text><Trans>Request Account Deletion</Trans></Text>
                                    </Menu.Item>
                                    <Divider />
                                    <Menu.Item
                                        onPress={() => {
                                            setLogoutModal(true);
                                        }}
                                    >
                                        <Icon color={colors['danger']['600']} size={16} name={'alternate-sign-out'} />
                                        <Text color={'danger.600'}><Trans>Log out</Trans></Text>
                                    </Menu.Item>
                                </Menu>
                            </Box>
                        </HStack>
                    </HStack>
                </Box>
                {isSafari() &&
                    <Box backgroundColor={'warning.600'} p={2}>
                        <Stack
                            direction={{
                                base: 'column',
                                lg: 'row'
                            }}
                            space={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Box>
                                <Text color={'#fff'}><Trans>Safari is currently not fully supported. The application will work better in other browsers.</Trans></Text>
                            </Box>
                            <Box>
                                <Button
                                    variant={'subtle'}
                                    colorScheme={'warning'}
                                    onPress={() => {
                                        Linking.openURL('https://www.mozilla.org/firefox/');
                                    }}
                                    leftIcon={<IconBrand style={{ color: colors['warning']['600'] }} name={'firefox'} size={20} />}
                                >
                                    <Text color={'warning.600'}><Trans>Download Firefox</Trans></Text>
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    variant={'subtle'}
                                    colorScheme={'warning'}
                                    onPress={() => {
                                        Linking.openURL('https://www.google.com/chrome/');
                                    }}
                                    leftIcon={<IconBrand style={{ color: colors['warning']['600'] }} name={'chrome'} size={20} />}
                                >
                                    <Text color={'warning.600'}><Trans>Download Chrome</Trans></Text>
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                }
            </SafeAreaView>
            <AlertDialog isOpen={accountRemoveModal} onClose={() => setAccountRemoveModal(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text><Trans>Remove account</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text><Trans>This wil signal to us that you wish to remove your account.</Trans></Text>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={0}>
                            <Button
                                variant={'unstyled'}
                                onPress={() => setAccountRemoveModal(false)}
                            >
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            <Button
                                variant={'solid'}
                                colorScheme={'danger'}
                                onPress={() => {
                                    setAccountRemoveModal(false);
                                    let send = async () => {
                                        let response = await APIAction.request({
                                            method: 'GET',
                                            url: '/api/request_account_removal'
                                        });
                                        GeneralAction.toast('success', <Trans>Request sent, admins wil contact you shortly.</Trans>);
                                    }
                                    send();
                                }}
                            >
                                <Text color={'#fff'}><Trans>Remove my account</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
            <AlertDialog isOpen={logoutModal} onClose={() => setLogoutModal(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text><Trans>Log out</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text><Trans>Are you sure you want to log out?</Trans></Text>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={0}>
                            <Button
                                variant={'unstyled'}
                                onPress={() => setLogoutModal(false)}
                            >
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            <Button
                                variant={'solid'}
                                colorScheme={'danger'}
                                onPress={() => {
                                    setLogoutModal(false);
                                    const logout = async () => {
                                        await UserAction.logOut();
                                        navigation.reset({
                                            index: 0,
                                            routes: [{
                                                name: 'Public'
                                            }]
                                        });
                                    };
                                    logout();
                                }}
                            >
                                <Text color={'#fff'}><Trans>Log out</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        </>
    );
};

export default Header;