import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import UserAction from '../../Actions/UserAction';
import RequestCacheAction from '../../Actions/RequestCacheAction';
import LoadingScreen from '../General/LoadingScreen';

const IsProfileComplete = (props) => {

    const navigation = useNavigation();

    useEffect(() => {
        const check = async () => {

            //get current user
            let user = await APIAction.request({
                method: 'GET',
                url: `/api/current/user`,
                cache: true
            });
            await UserAction.setUser(user);

            if (user) {
                let employee = user.employee;
                let hasPersonalDetails = ((user.firstName || '').length > 0 && (user.lastName || '').length > 0 && (user.phone || '').length > 0 && user.employee.birthDay != null);

                let hasEmployeeDetails = (employee.gender != null && employee.address && employee.placeOfBirth != null && employee.nationality != null && employee.maritalStatus != null && employee.contractType != null && employee.nationalRegistry != null);
                let hasAdditionalInfo = (employee.bodyLength != null && employee.sizeShoe != null && employee.sizeBottom != null && employee.sizeTop != null);

                let identityVerification = await APIAction.request({
                    method: 'GET',
                    url: '/api/identity_verifications/get/open',
                    cache: true
                });

                let paymentVerification = await APIAction.request({
                    method: 'GET',
                    url: '/api/payment_verifications/get/open',
                    cache: true
                });

                // Check for documents
                let hasDocumentsToSign = await APIAction.request({
                    method: 'GET',
                    url: '/api/check/contracts',
                    params: {
                        employeeId: user.employee.id,
                    },
                    cache: false
                });

                if (
                    !identityVerification || // Must have entered an id verification
                    !paymentVerification || // Must have entered a payment verification
                    !hasPersonalDetails || // Personal details (name, etc.) are required
                    !hasEmployeeDetails || // Employee details (gender, etc.) are required
                    //!hasAdditionalInfo || // Additional details (shoe size, etc) are required
                    !(user.employee.identityVerified) || // Must be approved by admin
                    !(user.employee.paymentVerified) || // Must be approved by admin
                    !(user.employee.iban) || // Must provide card details
                    !(user.employee.bic) ||
                    hasDocumentsToSign // All documents must be signed before user can work
                ) {
                    await RequestCacheAction.clear({ url: 'current/user' });
                    await RequestCacheAction.clear({ url: 'identity_verifications' });
                    await RequestCacheAction.clear({ url: 'payment_verifications' });
                    await RequestCacheAction.clear({ url: 'check/contracts' });
                    
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'Start' }],
                    });
                } else {
                    navigation.reset({
                        index: 0,
                        routes: [{ name: 'App' }],
                    });
                }
            }
        };
        check();
    }, [navigation]);

    return (
        <LoadingScreen />
    );
};

export default IsProfileComplete;