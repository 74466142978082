import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, HStack, Text, useTheme, IconButton, Skeleton, Spinner } from 'native-base';
import APIAction from '../../../../Actions/APIAction';
import GeneralAction from '../../../../Actions/GeneralAction';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import main from '../../../../Assets/Styles/main.json';
import FileFunctions from '../../../../Libs/FileFunctions';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const File = (props) => {

    const
        { colors } = useTheme(),
        loaded = useRef(false),
        [fileName, setFileName] = useState(null),
        [downloading, setDownloading] = useState(false)
        ;

    const load = useCallback(() => {
        const init = async () => {
            let fileId = await GeneralAction.iriToId(JSON.parse(JSON.stringify(props.file)));
            let fileInfo = await APIAction.request({ method: 'get', url: '/api/file/info/' + fileId });
            setFileName(fileInfo.name);
        };
        init();
    }, [setFileName, props.file]);

    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            load();
        }
    }, [load]);

    return (
        <>
            {fileName ?
                <Box>
                    <HStack
                        space={2}
                        alignItems={'center'}
                    >
                        <Box
                            flexGrow={1}
                            flexShrink={1}
                        >
                            <HStack
                                space={2}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <HStack style={{ flexShrink: 1 }} space={2} alignItems={'center'}>
                                    <Icon name={'file'} size={20} color={colors['primary']['600']} />
                                    <Text style={{ flexShrink: 1 }}>
                                        {fileName}
                                    </Text>
                                </HStack>
                                {downloading ?
                                    <Spinner size={32} color={colors['primary']['600']} />
                                    :
                                    <IconButton icon={<Icon color={colors['primary']['600']} size={12} name={'download'} />} borderRadius={'full'} colorScheme={'light'} variant={'subtle'} onPress={() => {
                                        let init = async () => {
                                            setDownloading(true);
                                            let file = await FileFunctions.download({ url: props.file });
                                            FileFunctions.share(file);
                                            setDownloading(false);
                                        };
                                        init();
                                    }} />
                                }
                            </HStack>
                        </Box>
                    </HStack>
                </Box>
                :
                <Skeleton height={'25px'} rounded={4} />
            }
        </>
    );
};

export default File;