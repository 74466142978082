import APIAction from '../../Actions/APIAction';

const FileFunctions = {

    /*
     * Download file, compatible api
     */
    download: async ({ url, params = null, authenticate = true }) => {

        //get file
        let response = await APIAction.request({ method: 'GET', url: url, params: params, authenticate: authenticate, catchFail: false, fileResponse: true });

        if (response === false) return {};

        //to base64
        let base64 = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(response);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });

        let result = {
            uri: base64
        };

        if (response.name) {
            result['name'] = response.name;
        }

        return result;
    },

    /*
     * Allow user to download/share file
     */   
    share: async (file) => {
        if (file.uri) {
            let link = document.createElement('a');
            link.href = file.uri;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export default FileFunctions;