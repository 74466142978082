import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import RouteState from '../../Recoil/RouteState';

const RoutePassListener = (props) => {

    let [routePass, setRoutePass] = useRecoilState(RouteState);
    let triggered = useRef(false);

    useEffect(() => {
        if (routePass && !triggered.current) {
            let link = JSON.parse(JSON.stringify(routePass));
            triggered.current = true;
            props.onTrigger(link);
        }
    }, [routePass, setRoutePass, props]);

    return (
        <></>
    );
}

export default RoutePassListener;