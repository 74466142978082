import React, { useEffect, useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { Box, HStack, HamburgerIcon, IconButton, Menu, Pressable, useTheme, Text, Button, VStack } from 'native-base';
import AppContainer from '../../Components/AppContainer';
import JobSwiper from './JobSwiper';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import Lottie from '../../../Libs/Lottie/index';
import lottiePencilCheckboxes from '../../../Assets/Lottie/pencil-checkboxes.json';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const JobSwiperScreen = ({ route, navigation }) => {

    const { colors } = useTheme();

    return (
        <AppContainer>
            <VStack space={2}>
            <VStack space={2}>
                <TouchableOpacity
                    style={{ backgroundColor: colors['primary'][600], borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => {
                        navigation.navigate('Availability');

                    }}
                >
                    <Lottie source={lottiePencilCheckboxes} autoPlay={true} loop={true} speed={1} style={{ width: 50, height: 50 }} />
                    <Text style={{ }} color={'white'}><Trans>Keep your availabilities up to date here!</Trans></Text>
                </TouchableOpacity>
            </VStack>
                <Box>
                    <JobSwiper
                        toId = {route.params?.toId}
                    />
                </Box>
                <Button
                    variant={'outline'}
                    borderColor={colors['primary']['600']}
                    onPress={() => navigation.navigate('CardHistory')}
                >
                    <HStack space={1} alignItems={'center'}>
                        <Icon color={colors['primary']['600']} name={'history'} size={16} />
                        <Text color={colors['primary']['600']}><Trans>History</Trans></Text>
                    </HStack>
                </Button>
            </VStack>
        </AppContainer>
    )
};

export default JobSwiperScreen;